<template>
  <button
    class="data-details__action"
    :class="{ disabled: isReviewAdded }"
    :disabled="isReviewAdded"
    type="button"
    @click="addReview"
  >
    <span>
      <TheReviewIcon v-if="!isReviewAdded" />
      <TheConfirmedIcon v-else />
    </span>
    <span>Add Review</span>
  </button>
</template>

<script>
import TheReviewIcon from '@/components/icons/TheReviewIcon.vue';
import TheConfirmedIcon from '@/components/icons/TheConfirmedIcon.vue';

export default {
  name: 'AddReviewAction',
  components: { TheConfirmedIcon, TheReviewIcon },
  data() {
    return {
      isReviewAdded: false,
    };
  },
  methods: {
    addReview() {
      this.$emit('click');

      if (!this.isReviewAdded) {
        this.$router.push(this.$route.path + '/reviews');
      }
    },
  },
};
</script>
