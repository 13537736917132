<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" fill="white" stroke="#5B5D62" stroke-width="1.5" />
    <path
      d="M10.0478 15.1406L7.23426 11.9778C7.19486 11.9319 7.14774 11.8953 7.09563 11.8701C7.04353 11.845 6.98749 11.8317 6.93078 11.8312C6.87408 11.8306 6.81784 11.8428 6.76535 11.8669C6.71287 11.8911 6.66519 11.9267 6.62509 11.9718C6.58499 12.017 6.55328 12.0706 6.5318 12.1296C6.51033 12.1887 6.49952 12.2519 6.50002 12.3157C6.50051 12.3795 6.51229 12.4425 6.53467 12.5011C6.55706 12.5597 6.58959 12.6127 6.63038 12.657L10.047 16.5L16.8801 8.81409C16.9579 8.72349 17.001 8.60215 17 8.4762C16.999 8.35024 16.9541 8.22976 16.8749 8.1407C16.7957 8.05163 16.6886 8.00111 16.5766 8.00002C16.4647 7.99892 16.3568 8.04734 16.2762 8.13485L10.0478 15.1406Z"
      fill="#5B5D62"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheConfirmedIcon',
};
</script>
