<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="27"
    viewBox="0 0 32 27"
  >
    <defs>
      <path
        id="gvona"
        d="M709.66 1224.5a2.82 2.82 0 0 1-2.85-2.8v-12.11a2.82 2.82 0 0 1 2.85-2.8h22.68a2.82 2.82 0 0 1 2.85 2.8v12.11a2.82 2.82 0 0 1-2.85 2.8h-13.62l-.28.07-6.63 5.22v-4.9a.4.4 0 0 0-.4-.4zm1.58 6.5h.17l.24-.08 7.2-5.62h13.5c2.01 0 3.65-1.6 3.65-3.59v-12.12a3.62 3.62 0 0 0-3.66-3.59h-22.68a3.62 3.62 0 0 0-3.66 3.59v12.12a3.62 3.62 0 0 0 3.66 3.59H711v5.34c0 .16.1.3.24.36z"
      ></path>
    </defs>
    <g>
      <g transform="translate(-705 -1205)">
        <use fill="#363636" xlink:href="#gvona"></use>
        <use
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-miterlimit="50"
          stroke-width=".43"
          xlink:href="#gvona"
        ></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheReviewIcon',
};
</script>
