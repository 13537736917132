<template>
  <header>
    <p v-show="titleBefore" class="data-details__cat text-center">{{ titleBefore }}</p>
    <h1 class="data-details__title">{{ title }}</h1>
    <slot name="title-after">
      <p v-show="titleAfter" class="data-details__cat text-center mt-3">{{ titleAfter }}</p>
    </slot>

    <div class="data-details__img mt-4" :class="imageClass" :style="`background-image:url(${image})`" />
  </header>
</template>

<script>
export default {
  name: 'EntityPageHeader',
  props: {
    image: {
      type: String,
      default: '',
    },
    imageClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    titleBefore: {
      type: String,
      default: '',
    },
    titleAfter: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.data-details__title {
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
}
</style>
